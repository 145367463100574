<template>
  <section style="height: 100%">
    <PowerBIReport
      :sliceFilter="reportFilters"
      :reportId="reportId"
      :siteId="siteId"
      :hasSubmit="false"
      ref="powerBIReport"
    ></PowerBIReport>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import PowerBIReport from "./PowerBIReport";

export default {
  name: "ReportDetailsModule",
  data() {
    return {
      report: null,
      reportId: null,   //this.$route.params.id,
      siteId: null, //this.$route.params.siteId,
     };
  },
  props: { reportFilters: { type: Object, required: false } },

  components: {
    PowerBIReport,
  },
  computed: {
    ...mapGetters({
      userId: "session/jwtSubject",
      permissions: "session/userPermissions",
    }),
  },
  created() {
    this.reportId =  this.$route.params.id;
    this.siteId = this.$route.params.siteId;
  },
};
</script>